<template>
  <mdb-container>
    <adminheader></adminheader>
    <section class="mt-3">
      <mdb-row>
        <mdb-col md="12">
          <h2>Liste des administrateurs</h2>
          <mdb-tbl striped>
            <mdb-tbl-body>
              <tr v-for="admin in admins" :key="admin.id">
                <td>{{admin.firstname}}</td>
                <td>{{admin.lastname}}</td>
                <td>{{admin.email}}</td>
                <td>
                  <mdb-btn size="sm" color="primary" v-on:click="editAdmin(admin.id)">Modifier</mdb-btn>
                  <mdb-btn size="sm" color="danger" v-on:click="askdelete(admin.id, admin)">Supprimer</mdb-btn>
                </td>
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>
          <mdb-btn v-on:click="addAdmin()">Ajouter un administrateur</mdb-btn>
        </mdb-col>
      </mdb-row>
    </section>
    <mdb-modal :show="settodelete != false" @close="settodelete = false" danger>
      <mdb-modal-header>
        <p class="heading">Supression d'un administrateur</p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3">
            <p class="text-center mt-3"><mdb-icon icon="exclamation" size="4x"/></p>
          </mdb-col>
          <mdb-col col="9" class="pt-3">
            <p>Êtes-vous certain de vouloir supprimer l'administrateur <strong>{{ currentuser.firstname }} {{ currentuser.lastname }}</strong> ?</p>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn tag="a" color="danger" v-on:click="confirmdelete()">Supprimer</mdb-btn>
        <mdb-btn tag="a" outline="danger" @click="settodelete = false">Annuler</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
    import Vue from 'vue';
    import Adminheader from '@/components/Adminheader.vue';

    import {
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbTbl,
        mdbTblBody,
        mdbBtn,
        mdbModal,
        mdbModalHeader,
        mdbModalBody,
        mdbModalFooter,
        mdbIcon,
    } from 'mdbvue';

    import Auth from '../libs/Auth';
    import Apicall from '../libs/Apicall';

    export default Vue.extend({
        name: 'Dashboard',
        components: {
            Adminheader,
            mdbContainer,
            mdbRow,
            mdbCol,
            mdbTbl,
            mdbTblBody,
            mdbBtn,
            mdbModal,
            mdbModalHeader,
            mdbModalBody,
            mdbModalFooter,
            mdbIcon,
        },
        data() {
            return {
                dummy: '',
                admins: [],
                settodelete: false,
                currentuser: [],
            };
        },
        mounted() {
            Auth.validateIfCanAccess('ADMINISTRATOR', 'Dashboard');
            this.getAndDisplayAdministrators();
        },
        methods: {
            getAndDisplayAdministrators() {
                const self = this;
                const api = new Apicall();
                api.getAllAdmins().then((response) => {
                    if (response.status === 'OK') {
                        self.admins = response.data.admins;
                    } else {
                        console.log('Erreur!!!');
                    }
                });
            },
            editAdmin(_adminid) {
                this.$router.push({ name: 'Adminedit', params: { adminid: _adminid } });
            },
            addAdmin() {
                this.$router.push({ name: 'Adminadd' });
            },
            askdelete(_adminid, _infos) {
                this.currentuser = _infos;
                this.settodelete = _adminid;
                console.log(_infos);
            },
            confirmdelete() {
                const self = this;
                const api = new Apicall();
                api.deleteAdmin(this.settodelete).then(() => {
                    this.settodelete = false;
                    self.getAndDisplayAdministrators();
                });
            },
        },
    });
</script>
